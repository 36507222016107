@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

/**
    * close button
    */
.modal-deal-info-close {
  position: absolute;
  top: 70px;
  right: 8px;
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 7px; 
  background-color: $ui-btn-white;
  transition: all 0.35s ease-out 0s;

  .modal-deal-info-close-icon {
    width: 20px;
    height: 20px;
    fill: $red;
    transition: all 0.35s ease-out 0s;
  }

  &:hover {
    background-color: $ui-btn-dark;
    transition: all 0.35s ease-out 0s;

    .modal-deal-info-close-icon {
      fill: $ui-icon-white;
      transition: all 0.35s ease-out 0s;
    }
  }
}


#page.product-add-page {
  margin: 20px 0;
  background-color: $ui-bg-white;
  margin-bottom: 140px !important;


  .product-add-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;

    .product-add-about {
      .product-add-about-title {
        margin-bottom: 20px;
      }
    }

    .product-add-info-photo {
      margin: 10px 0;

      .card-add-photo-wrap {
        max-width: 250px;
      }


    }
  }

  .product-add-info-alert {
    border: 2px solid $ui-line-dark;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;

    font-size: 14px;

    b {
      color: $red;

    }
  }
  

  .table-adaptive {
    padding-bottom: 0px;
  }

  .add-replace-wrap {
    border: 2px solid $purple;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 15px;

    .added-list {
      border-bottom: 2px solid $purple;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .card-add-photo-wrap {
      width: 200px;
    }

  }

  .product-add-info-tags {
    border-radius: 20px;
    //background-color: $purple;
    color: $ui-bg-white;
    padding: 10px 8px;
    width: 120px;
    margin: 2px;
    position: relative;


    p {
      display: inline-block;
      width: 60px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 7px;
      display: inline-block;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      background-color: $ui-bg-white;
      color: $red;
    }


    /* colors line */
    .red {

      background-color: $red;
    }

    .pink {

      background-color: $cyan;
    }

    .purple {

      background-color: $purple;

    }

    .violet {

      background-color: $purple;

    }

    .primary {

      background-color: $amber;

    }

    .blue {

      background-color: $blue;

    }

    .sky {

      background-color: $sky;
    }
  }


  .product-add-info-tags-wrap {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 0;
  }

  .product-add-info-container {
    border: 2px solid #7b1fa2;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 15px;
 
  }

  .product-add-info-tags {
    span {
      margin: 1px;
      padding: 5px;
      border-radius: 5px;
      width: 120px
    }


    /* colors line */
    .red {

      background-color: $red;
    }

    .pink {

      background-color: $pink;
    }

    .purple {

      background-color: $purple;

    }

    .violet {

      background-color: $purple;

    }

    .primary {

      background-color: $indigo;

    }

    .blue {

      background-color: $blue;

    }

    .sky {

      background-color: $sky;
    }
  }

  .cyan {

    background-color: $cyan;

  }

  .teal {

    background-color: $teal;

  }

  .grass {

    background-color: $lime;

  }

  .green {

    background-color: $green;

  }

  .lime {

    background-color: $olive;

  }

  .yellow {

    background-color: $yellow;

  }

  .amber {

    background-color: $amber;

  }

  .orange {

    background-color: $orange;

  }

  .brick {

    background-color: $brick;

  }

  .gray {

    background-color: $gray;

  }

  .dark {

    background-color: $dark;

  }

}

.save-box {
  bottom: 0;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  position: fixed;
  right: 0;
  text-align: center;
  transition: left .25s linear !important;
  width: 100%;
  z-index: 200;

  .wrapper {
    min-height: 56px;
    padding-bottom: 9px;
    padding-top: 10px;
    position: relative;

    background-color: #fff;
    border-top: 1px solid #dee2e6;

  }

  .save-box .btn__block {
    float: left;
    margin-left: 0;
  }

  .btn-split {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
  }

  .btn-split-right {
    margin-left: auto;
  }


  .button-wrap {
    width: 140px;
    padding: 8px;
  }


}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {}