@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

#page.product-add-page {
  margin: 20px 0;
  background-color: $ui-bg-white;


  .product-add-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;

    .product-add-about {
      .product-add-about-title {
        margin-bottom: 20px;
      }
    }

    .product-add-info-photo {
      margin: 10px 0;

      .card-add-photo-wrap {
        max-width: 250px;
      }


    }
  }

  .table-adaptive {
    padding-bottom: 0px;
  }

  .add-replace-wrap {
    border: 2px solid $purple;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 15px;

    .added-list {
      border-bottom: 2px solid $purple;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .card-add-photo-wrap {
      width: 200px;
    }
  
  }
 
  .product-add-info-tags {
    border-radius: 20px;
    //background-color: $purple;
    color: $ui-bg-white;
    padding: 10px 8px;
    width: 120px;
    margin: 2px;
    position: relative;


    p {
      display: inline-block;
      width: 60px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 7px;
      display: inline-block;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      background-color: $ui-bg-white;
      color: $red;
    }


    /* colors line */
    .red {

      background-color: $red;
    }

    .pink {

      background-color: $cyan;
    }

    .purple {

      background-color: $purple;

    }

    .violet {

      background-color: $purple;

    }

    .primary {

      background-color: $amber;

    }

    .blue {

      background-color: $blue;

    }

    .sky {

      background-color: $sky;
    }
  }


  .product-add-info-tags-wrap {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 0;
  }


  .product-add-info-tags {
    span {
      margin: 1px;
      padding: 5px;
      border-radius: 5px;
      width: 120px
    }


    /* colors line */
    .red {

      background-color: $red;
    }

    .pink {

      background-color: $pink;
    }

    .purple {

      background-color: $purple;

    }

    .violet {

      background-color: $purple;

    }

    .primary {

      background-color: $indigo;

    }

    .blue {

      background-color: $blue;

    }

    .sky {

      background-color: $sky;
    }
  }

  .cyan {

    background-color: $cyan;

  }

  .teal {

    background-color: $teal;

  }

  .sky {

    background-color: $sky;
  }

  .violet {

    background-color: $purple;

  }

  .gray {

    background-color: $gray;

  }

  
  .grass {

    background-color: $lime;

  }

  .green {

    background-color: $green;

  }

  .lime {

    background-color: $olive;

  }

  .yellow {

    background-color: $yellow;

  }

  .amber {

    background-color: $amber;

  }

  .orange {

    background-color: $orange;

  }

  .brick {

    background-color: $brick;

  }


  .dark {

    background-color: $dark;

  }

}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {}